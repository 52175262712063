@import "../../../../../shared/styles/media";
@import "../../../../../shared/styles/typography";

.media_section {
  background: var(--white);
  width: 100%;
  padding: 100px var(--container-horizontal-padding);

  @include md {
    padding: 80px var(--container-horizontal-padding);
  }

  .container {
    max-width: var(--container-width);
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 79px;

    @include sm {
      gap: 30px;
    }

    .heading_content {
      display: flex;
      flex-direction: column;

      gap: 30px;
      max-width: 730px;
      text-align: center;
      margin: 0 auto;

      .heading_content_title {
        margin-bottom: 10px;
        @extend %section_title_bottom_line;
      }

      .heading_content_sub_title {
        @extend %big_txt;
      }

      .heading_content_description {
        @extend %paragraph_18_reg;
        color: var(--medium-gray);
      }

      @include sm {
        text-align: left;
        .title {
          &::after {
            left: 0;
            transform: translate(0);
          }
        }
      }
    }

    .accent_txt {
      color: var(--accent);
    }

    .content_cards {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;

      .chapter {
        display: flex;
        gap: 20px;

        @media (max-width: 992px) {
          flex-wrap: wrap;
        }

        .row {
          width: calc((100% / 3) * 2);
          display: block;

          @media (max-width: 992px) {
            width: 100%;
          }
        }

        &.col {
          flex-direction: column;
          width: calc(100% / 3);

          @media (max-width: 992px) {
            width: 100%;
            flex-direction: row;
          }
        }
      }

      .card {
        display: flex;
        transition: transform 0.3s linear;
        position: relative;
        &:hover {
          transition: transform 0.3s linear;
          transform: scale(1.03);
          color: initial;
        }

        .extra_link_icon {
          position: absolute;
          top: 20px;
          right: 20px;
          width: 23px;
          height: 23px;
          z-index: 99;
        }

        &.telegram {
          width: 100%;
          height: 100%;
          flex-direction: row;
          align-items: center;
          justify-content: space-evenly;
          background: var(--black);
          color: var(--white);
          padding: 48px;
          gap: 45px;
          cursor: pointer;

          @media (max-width: 600px) {
            padding: 20px;
            flex-direction: column;
            gap: 30px;
          }

          .image {
            max-width: 300px;
            width: calc(50% - 45px);
            height: auto;

            @media (max-width: 600px) {
              width: 100%;
              max-width: 100%;
            }
          }

          .content {
            display: flex;
            flex-direction: column;
            gap: 30px;
            max-width: 330px;
            width: calc(50%);
            height: 100%;
            align-items: flex-start;
            text-align: left;
            justify-content: center;

            @media (max-width: 600px) {
              width: 100%;
              max-width: 100%;
              align-items: flex-start;
            }

            .heading {
              display: flex;
              flex-direction: column;

              .title {
                @extend %header_2_txt;
              }

              .sub_title {
                @extend %big_txt;
              }
            }

            .description {
              @extend %paragraph_16_reg;
            }

            @include sm {
              button {
                width: 100%;
              }
            }
          }
        }

        &.vc_ru {
          border: 1px solid var(--stroke-color);
          background: var(--white);
          display: flex;
          flex-direction: column;
          gap: 22px;
          padding: 20px;
          padding-bottom: 33px;
          width: 100%;
          cursor: pointer;

          .logo_box {
            display: flex;
            width: 100px;
            height: 100px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            background: #fde7f7;
          }

          .logo {
            width: 100%;
            height: auto;
          }

          .content {
            display: flex;
            flex-direction: column;
            gap: 10px;
          }

          .title {
            @extend %header_2_txt;
          }

          .description {
            @extend %paragraph_16_reg;
          }
        }

        &.youtube {
          background: var(--background);
          display: flex;
          flex-direction: column;
          gap: 22px;
          padding: 20px;
          height: 100%;
          cursor: pointer;

          @include sm {
            height: auto;
          }

          .heading {
            .avatar {
              width: 100px;
              height: auto;
            }
          }

          .content {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .title {
              display: inline-flex;
              gap: 5px;
              align-items: center;
              .youtube_icon {
                width: 41px;
                height: auto;
              }
            }
          }

          .title {
            @extend %header_2_txt;
          }

          .description {
            @extend %paragraph_16_reg;
          }
        }
      }

      @media (max-width: 992px) {
        .chapter.col .card {
          width: calc(100% / 2 - 10px);
        }
      }

      @media (max-width: 600px) {
        .chapter.col .card {
          width: 100%;
          padding: 20px;
          gap: 15px;
        }
      }

      .card.excursion {
        width: calc(100% / 3);
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        overflow: hidden;
        background: var(--accent-dark);
        color: var(--white);

        @include md {
          width: calc(50% - 10px);
          min-height: 550px;
        }

        @include sm {
          width: 100%;
        }

        .title {
          text-align: center;
          padding: 30px 30px 20px 30px;

          @extend %header_2_txt;
        }

        .image {
          width: 100%;
          height: auto;
          object-fit: contain;
          position: absolute;
          bottom: 0;
          z-index: 0;
          margin-top: auto;
        }

        .play_button_box {
          width: 100px;
          height: 100px;
          cursor: pointer;
          position: relative;
          border-radius: 9999999px;
          overflow: hidden;
          z-index: 9;

          svg {
            width: 100%;
            height: auto;
          }
        }

        video {
          display: none;
          &.show {
            display: block;
          }
        }
      }

      .card.vk {
        width: calc(100% / 3);
        height: auto;
        display: flex;
        flex-direction: column;
        padding: 39px 30px 30px;
        justify-content: space-between;
        background: #3e3e3e;
        color: var(--white);
        gap: 22px;
        cursor: pointer;

        @include md {
          width: calc(50% - 10px);
        }

        @include sm {
          width: 100%;
        }

        .logo {
          width: 100px;
          height: auto;
        }

        .title {
          @extend %header_2_txt;
        }

        .sub_title {
          @extend %big_txt;
        }

        .description {
          margin-top: auto;

          @extend %paragraph_16_reg;
        }
      }

      .card.video {
        width: calc(100% / 3);
        height: 100%;
        position: relative;
        overflow: hidden;

        @include md {
          width: 50%;
          align-self: center;
          margin: 0 auto;
          height: 100vw;
        }

        @include sm {
          width: 100%;
          height: 100vw;
        }

        .video_wrapper {
          width: 100%;
        }

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          max-height: 540px;
          height: auto;

          @media (max-width: 600px) {
            max-height: inherit;
          }
        }
      }
    }
  }
}
