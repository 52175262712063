.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .play_button {
    position: absolute;
    z-index: 3;
    cursor: pointer;
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 99999px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    padding: 0;
    border: none;
    .play_icon {
      width: 100%;
      height: 100%;
    }
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  video {
    width: 100%;
    height: auto;
  }

  &.video_not_autoplay {
    video {
      z-index: 1;
      position: absolute;
    }
  }

  &.played {
    video {
      position: relative;
    }
  }

  &.playing {
    video {
      position: relative;
      z-index: 9;
    }
  }
}
