@import "../../../../../../../../shared/styles/media";
@import "../../../../../../../../shared/styles/typography";

.card {
  min-height: 517px;
  height: 70vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video_card {
  width: 60%;
  user-select: none;
  .video {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    &.show {
      z-index: 9;
    }
  }

  .content {
    position: relative;
    z-index: 2;
    color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 40px 40px 65px 40px;

    .accent_txt {
      color: var(--accent);
    }

    .title {
      @extend %header_1_txt;
    }

    .desc {
      font-family: var(--font-open-sans-condensed);
      font-size: 50px;
      font-style: italic;
      font-weight: 300;
      line-height: 120%; /* 60px */
      letter-spacing: -1.5px;
    }
  }

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 35.42%, rgba(0, 0, 0, 0) 70.83%, #000 100%);
  }

  .image {
    position: absolute;
    inset: 0;
    width: auto;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  .play_button {
    position: absolute;
    width: 10%;
    height: auto;
    cursor: pointer;
    z-index: 5;
  }

  @include lg {
    width: 100%;
    height: 50vw;
    .content {
      text-align: center;
    }
  }

  @include md {
    width: 100%;
    height: 75vw;
    min-height: 300px;

    .content {
      padding: 20px;

      .desc {
        font-size: 40px;
        letter-spacing: -1px;
      }
    }
  }

  @include sm {
    .content {
      text-align: center;
      .title {
        font-size: 24px;
      }

      .desc {
        font-size: 20px;
        letter-spacing: -0.5px;
      }
    }
  }
}
